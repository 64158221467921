import { hookstate, useHookstate } from '@hookstate/core';
import request from './service';
import { notifications } from '@mantine/notifications';

const _state = hookstate({
	currentStep: 'brand-integrations-list',
	form: {
		modalOpen: false,
		loading: false,
		data: {
			id: '',
			key: '',
			name: '',
			logoUrl: '',
			authorizeHost: '',
			authMethod: '',
			authorizePath: '',
			hasDomainPrefix: false,
			baseDomain: '',
			domainPrefix: '',
			scope: [],
			redirectUrl: '',
			clientId: '',
		},
	},
});

export const useIntegration = () => {
	const session = useHookstate(_state);

	const setStep = (step) => {
		session.currentStep.set(step);
	};

	const closeModal = () => {
		session.form.modalOpen.set(false);
	};

	const editBrandConnector = async (brandId, connectorKey) => {
		session.form.loading.set(true);
		session.form.modalOpen.set(true);

		const { status = false, config = {} } = await request.get(
			`/web/brand/${brandId}/connector/${connectorKey}/config`
		);

		session.form.data.set((prev) => ({
			...prev,
			...config,
		}));

		session.form.loading.set(false);

		if (!status) {
			notifications.show({
				title: 'Error',
				color: 'red',
				message: 'Error loading connector config',
			});
			return;
		}
	};

	return {
		state: session.get(),
		setStep,
		editBrandConnector,
		closeModal,
	};
};
