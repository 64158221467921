import { hookstate, useHookstate } from '@hookstate/core';
import { get } from 'lodash';
import { getInvitationInfo } from '../../api';

const localState = hookstate({
	step: 'start',
	store: {
		id: '',
		name: '',
		logoUrl: '',
	},
	user: {
		email: '',
	},
});

export const useLocalStore = () => {
	const state = useHookstate(localState);

	const setStep = (step) => {
		state.set((prev) => ({
			...prev,
			step,
		}));
	};

	const loadData = async (uuid) => {
		const { status = false, invitation = {} } = await getInvitationInfo(uuid);

		if (!status) {
			return false;
		}

		state.set((row) => ({
			...row,
			store: {
				id: get(invitation, 'storeId', ''),
				name: get(invitation, 'storeName', ''),
				logoUrl: get(invitation, 'storeLogoUrl', ''),
			},
			user: {
				email: get(invitation, 'email', ''),
			},
		}));

		return true;
	};

	const onSelectStore = (store) => {
		state.set((row) => ({
			...row,
			store,
			step: 'form',
		}));
	};

	return {
		state,
		setStep,
		loadData,
		onSelectStore,
	};
};
