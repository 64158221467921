import { Fragment, useEffect, useMemo, useState } from 'react';
import { useTable, usePagination, useExpanded, useSortBy } from 'react-table';
import ReactPaginate from 'react-paginate';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { TbDots } from 'react-icons/tb';

import * as s from './styles';

const TableComponent = ({
	columns,
	data,
	fetchData,
	pageCount: controlledPageCount,
	loading,
	isPaginated = true,
	onRowClick,
	initialPageSize = 15,
	...props
}) => {
	const defaultColumn = useMemo(
		() => ({
			// minWidth: 20,
			// maxWidth: 115
		}),
		[]
	);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		pageOptions,
		pageCount,
		gotoPage,
		nextPage,
		previousPage,
		setPageSize,
		setHiddenColumns,
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			initialState: {
				pageIndex: 0,
				pageSize: initialPageSize,
				hiddenColumns: columns.filter((column) => !column.show).map((column) => column.id),
			},
			manualPagination: true,
			manualSortBy: true,
			autoResetPage: false,
			pageCount: controlledPageCount,
		},
		useSortBy,
		useExpanded,
		usePagination
	);

	useEffect(() => {
		fetchData && fetchData({ pageIndex, pageSize });
	}, [fetchData, pageIndex, pageSize]);

	const handlePageClick = (event) => {
		gotoPage(event.selected);
	};

	return (
		<Fragment>
			{loading ? (
				<div>
					<span>loading...</span>
				</div>
			) : (
				<div>
					<table className="table" {...getTableProps()}>
						<thead>
							{headerGroups.map((headerGroup) => (
								<tr {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map((column) => (
										<th {...column.getHeaderProps()}>{column.render('Header')}</th>
									))}
								</tr>
							))}
						</thead>
						<tbody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<tr
										{...row.getRowProps()}
										onClick={() => (onRowClick ? onRowClick(row.original) : {})}
									>
										{row.cells.map((cell) => {
											return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
										})}
									</tr>
								);
							})}
						</tbody>
					</table>
					{Boolean(isPaginated) && (
						<s.Pagination>
							<ReactPaginate
								breakLabel={<TbDots />}
								nextLabel={<FaChevronRight />}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								pageCount={controlledPageCount}
								previousLabel={<FaChevronLeft />}
								renderOnZeroPageCount={null}
							/>
						</s.Pagination>
					)}
				</div>
			)}
		</Fragment>
	);
};

export default TableComponent;
