import { When } from 'react-if';
import { ActionIcon, Button } from '@mantine/core';
import { Container } from './styles';
import { FaCircleChevronLeft } from 'react-icons/fa6';

const Header = ({ pageTitle = null, onBack = null, children }) => {
	return (
		<Container>
			<div className="main">
				<When condition={onBack !== null}>
					<Button onClick={onBack} variant="subtle" leftIcon={<FaCircleChevronLeft size={24} />}>
						Back
					</Button>
				</When>

				<When condition={pageTitle}>
					<h3 className="title">{pageTitle}</h3>
				</When>
			</div>

			<div className="actions">{children}</div>
		</Container>
	);
};

export default Header;
