import axios from 'axios';

const instanceV2 = axios.create({
	baseURL: process.env.API_URL || 'https://apis.endvr.io',
});

instanceV2.interceptors.response.use(
	(response) => {
		const { data = {} } = response;

		return data;
	},
	(error) => {
		console.log(error);
	}
);

export default instanceV2;
