import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import AuthLayout from '../../layouts/Auth';
import { Switch, Case, Default } from 'react-if';
import { Container } from './styles';
import GridLoader from 'react-spinners/GridLoader';
import StepStart from './StepStart';
import StepPickStore from './StepPickStore';
import StepForm from './StepForm';
import Confirmation from './Confirmation';
import { useLocalStore } from './store';

// TODO: add loading

const Page = () => {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();

	const localStore = useLocalStore();

	const state = localStore.state.get();

	const { id } = useParams();

	useEffect(() => {
		loadData();
	}, []);

	const loadData = async () => {
		setLoading(true);
		const status = await localStore.loadData(id);

		if (!status) {
			navigate('/');
			return;
		}

		setLoading(false);
	};

	if (localStore.state.get().step === 'confirmation') {
		return <Confirmation />;
	}

	return (
		<AuthLayout>
			<Container>
				<Switch>
					<Case condition={loading}>
						<div className="loading">
							<GridLoader size={80} color="#bcbcbc" />
						</div>
					</Case>
					<Case condition={state.step === 'start'}>
						<StepStart />
					</Case>
					<Case condition={state.step === 'pick-store'}>
						<StepPickStore />
					</Case>
					<Case condition={state.step === 'form'}>
						<StepForm />
					</Case>
				</Switch>
			</Container>
		</AuthLayout>
	);
};

export default Page;
