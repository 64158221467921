import { useState } from 'react';
import { StepForm } from './styles';
import { useLocalStore } from './store';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { acceptInvitation } from '../../api';

const validationSchema = yup
	.object({
		fullName: yup
			.string()
			.required('Name is required')
			.min(3, 'Name must be at least 3 characters'),
		password: yup.string().required().min(6),
	})
	.required();

const Step = () => {
	const localStore = useLocalStore();
	const { uuid } = useParams();
	const [loading, setLoading] = useState(false);

	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
	} = useForm({
		resolver: yupResolver(validationSchema),
	});

	const onSubmit = async ({ fullName, password }) => {
		setLoading(true);
		const { status, message } = await acceptInvitation({
			uuid,
			storeId: localStore.state.get().store.id,
			fullName,
			password,
		});

		setLoading(false);

		if (!status) {
			toast.error(message);
			return;
		}

		localStore.setStep('confirmation');
	};

	return (
		<StepForm>
			<div className="header">
				<div className="title">Create your account</div>
				<div className="subtitle">Fill in the fields below to complete the registration.</div>
			</div>

			<div className="request-info">
				<div className="email">
					<span>Your email: </span>
					<strong>{localStore.state.get().user.email}</strong>
				</div>
				<div className="store">
					<span>Registering at the store: </span>
					<strong>{localStore.state.get().store.name}</strong>
				</div>
			</div>

			<div className="reg-form-container">
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className="input-group">
						<label htmlFor="#form-name">Name</label>
						<input readOnly={loading} type="text" id="form-name" {...register('fullName')} />
						<span className="validation">{errors.fullName?.message}</span>
					</div>

					<div className="input-group">
						<label htmlFor="#form-password">Password</label>
						<input
							readOnly={loading}
							type="password"
							id="form-password"
							{...register('password')}
						/>
						<span className="validation">{errors.password?.message}</span>
					</div>

					<button disabled={loading} type="submit" className="confirm-btn">
						Confirm
					</button>
				</form>
			</div>
		</StepForm>
	);
};

export default Step;
