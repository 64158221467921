import GlobalStyle from './styles/global';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import InvitationPage from './pages/Invitation';
import LinkInvitationPage from './pages/LinkInvitation';
import MobileRedirectPage from './pages/MobileRedirect';
import ConfirmEmailPage from './pages/ConfirmEmail';
import IntegrationPage from './pages/Integration';
import NotFoundPage from './pages/404';
// trigger
const App = () => {
	return (
		<Router>
			<Routes>
				<Route exact path="/brand/:brandId/integration" element={<IntegrationPage />} />
				<Route exact path="/invitation/:uuid" element={<InvitationPage />} />
				<Route exact path="/link-invitation/:id" element={<LinkInvitationPage />} />
				<Route exact path="/mobile-reset-password" element={<MobileRedirectPage />} />
				<Route exact path="/user-email-verification" element={<ConfirmEmailPage />} />
				<Route path="*" element={<NotFoundPage />} />
			</Routes>
			<GlobalStyle />
			<Toaster position="top-center" reverseOrder={false} />
		</Router>
	);
};

export default App;
