import styled from 'styled-components';
import { Modal } from '@mantine/core';

export const CustomModal = styled(Modal)`
	.mantine-Modal-body {
		padding: 0;
	}

	.modal-body {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 32px;
		row-gap: 32px;

		.header {
			display: flex;
			flex-direction: column;
			align-items: center;

			.logos-header {
				position: relative;
				width: 254px;

				> svg {
					position: relative;
					z-index: 1;
				}

				.direction {
					position: absolute;
					font-size: 30px;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					z-index: 4;
					color: #adb5bd;
				}

				.logo-container {
					width: 130px;
					height: 130px;
					background-color: #edf3f7;
					border-radius: 50%;
					position: absolute;
					top: 7px;
					overflow: hidden;
					display: flex;
					justify-content: center;
					align-items: center;

					img {
						object-fit: cover;
					}

					&.connector {
						left: 7px;
						z-index: 3;

						img {
							width: 84px;
							aspect-ratio: 1/1;
						}
					}

					&.endvr {
						right: 7px;
						z-index: 2;

						img {
							position: relative;
							left: 5px;
							top: 5px;
						}
					}
				}
			}

			.description-header {
				text-align: center;
				display: flex;
				flex-direction: column;
				align-items: center;
				row-gap: 8px;

				> h3 {
					font-size: 24px;
					font-weight: 700;
					color: #343640;
				}

				> div {
					font-size: 16px;
					font-style: normal;
					font-weight: 400;
					color: #777;
				}
			}
		}

		.domain-prefix-container {
			width: 100%;
		}
	}

	.data-security-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		row-gap: 16px;

		.intro {
			color: #4a4a4a;
			text-align: center;
			font-size: 12px;
			font-style: normal;
			font-weight: 400;
			line-height: 16px;
		}

		label,
		.mantine-Checkbox-label {
			color: #777;
			font-size: 12px;

			a {
				color: #6f56bc;
				font-weight: 600;
			}
		}

		.link-container {
			color: #343640;
			font-size: 16px;

			a {
				color: #6f56bc;
			}
		}
	}

	.modal-footer {
		display: flex;
		justify-content: flex-end;
		column-gap: 24px;

		padding: 16px 32px;
		background: #edf3f7;
	}
`;
