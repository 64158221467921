import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	width: 100vw;
	min-height: 100vh;
	background: url(${require('../../static/images/auth-bg.png')});
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	top: 0;
	left: 0;

	.banner-container {
		width: calc(100% - 704px);
		background: url(${require('../../static/images/auth-banner-bg.png')});
		background-repeat: no-repeat;
		background-size: contain;
		background-position: left bottom;
		display: flex;
		align-items: center;
		justify-content: center;

		.endvr {
			width: 318px;
			height: 88px;
			max-width: 90%;
			background: url(${require('../../static/images/white-logo.png')});
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	.form-container {
		width: 704px;
		background-color: #fff;
		/* padding: 104px 104px 60px; */
		display: flex;
		flex-direction: column;
		padding-top: 104px;
		padding-bottom: 60px;
		align-items: center;

		> div {
			width: calc(100% - 208px) !important;

			&.header {
				height: 48px;
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				margin-bottom: 72px;

				.endvr {
					width: 176px;
					height: 48px;
					background: url(${require('../../static/images/dark-logo.png')});
					background-repeat: no-repeat;
					background-size: cover;
				}
			}

			&.content {
				width: 100%;
				height: calc(100% - 120px) !important;
			}
		}
	}

	@media (max-width: 980px) {
		flex-direction: column;
		align-items: center;

		.banner-container {
			background-image: unset;
			height: 130px;
			width: 100%;
		}

		.form-container {
			border-radius: 10px;
			padding-top: 24px;
			padding-bottom: 24px;
			max-width: calc(100% - 48px);
			height: calc(100vh - 200px);

			> div {
				width: calc(100% - 48px) !important;

				&.header {
					height: 24px;
					margin-bottom: 40px;

					.endvr {
						width: 88px;
						height: 24px;
					}
				}

				&.content {
					height: calc(100% - 64px) !important;
					overflow-x: hidden;
				}
			}
		}
	}
`;
