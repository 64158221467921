import { Container } from './styles';

const Page = () => {
	return (
		<Container>
			<div className="container">
				<i />
				<h1>404</h1>
				<h3>Ooops!</h3>
				<div>Page Not Found</div>
				<span>The page you are looking for is not available.</span>
			</div>
		</Container>
	);
};

export default Page;
