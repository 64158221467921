import { ConfirmContainer } from './styles';
import { useLocalStore } from './store';

const Page = () => {
	const localStore = useLocalStore();
	return (
		<ConfirmContainer>
			<div className="container">
				<i />
				<h1>Congratulations {localStore.state.get().user.name}!</h1>
				<h3>Your account has been created successfully!</h3>
				<span>
					Now install our app, log into your account, and start earning incredible rewards
				</span>

				<div className="download-tags">
					<div className="brand">
						<div className="logo" />
						<div className="info">
							<span>ENDVR</span>
						</div>
					</div>

					<div className="links">
						<a
							href="https://itunes.apple.com/ca/app/endvr/id1445917019?mt=8"
							className="apple-store"
						>
							<img src={require('../../static/images/apple-store.png')} />
						</a>

						<a
							href="https://play.google.com/store/apps/details?id=com.endvr"
							className="google-play"
						>
							<img src={require('../../static/images/google-play.png')} />
						</a>
					</div>
				</div>
			</div>
		</ConfirmContainer>
	);
};

export default Page;
