import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
  flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	background: url(${require('../../static/images/mobile-bg.png')});
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	top: 0;
	left: 0;

  > .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  > h1 {
    font-size: clamp(1.5rem, 0.7399rem + 3.2432vw, 3.375rem);
    font-weight: 700;
    color: #fff;
    margin-bottom: 16px;
  }

  > span {
    color: #fff;
    font-weight: 400;
    font-size: clamp(0.75rem, 0.598rem + 0.6486vw, 1.125rem);
    text-align: center;
  }
`;
