import Header from '../../components/Header';
import {
	Button,
	Pagination,
	Badge,
	Switch as SwitchInput,
	LoadingOverlay,
	SimpleGrid,
} from '@mantine/core';
import { Container } from './styles';
import { useEffect, useState } from 'react';
import { Switch, Case, When, Default } from 'react-if';
import request from '../../service';
import { useIntegration } from '../../hook';

const ConnectorListStep = ({ brandId }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const integration = useIntegration();

	const [pagination, setPagination] = useState({
		active: 1,
		perPage: 10,
		total: 1,
	});

	useEffect(() => {
		loadData();
	}, [brandId, pagination.active]);

	const loadData = async () => {
		setLoading(true);

		const query = new URLSearchParams({
			currentPage: pagination.active,
			perPage: pagination.perPage,
		});

		const { items = [], _meta } = await request.get(
			`/web/brand/${brandId}/available-connectors?${query}`
		);

		setData(items);

		setPagination((prev) => ({
			...prev,
			active: _meta.currentPage,
			perPage: _meta.perPage,
			total: _meta.pageCount,
		}));

		setLoading(false);
	};

	return (
		<Container>
			<Header onBack={() => integration.setStep('brand-integrations-list')} />

			<div className="result-container">
				<LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
				<Switch>
					<Case condition={data.length === 0}>
						<div className="empty">
							<div className="icon" />
							<h3>You've used all available connectors; none remain in your list.</h3>
						</div>
					</Case>
					<Case condition={data.length > 0}>
						<SimpleGrid
							className="result-grid"
							cols={5}
							spacing="lg"
							breakpoints={[
								{ maxWidth: '62rem', cols: 3, spacing: 'md' },
								{ maxWidth: '48rem', cols: 2, spacing: 'sm' },
								{ maxWidth: '36rem', cols: 1, spacing: 'sm' },
							]}
						>
							{data.map((item) => (
								<div
									key={`result-item-${item.id}`}
									onClick={() => integration.editBrandConnector(brandId, item.key)}
								>
									<div className="connector-info">
										<div className="logo">
											<img src={item.logoUrl} alt={item.name} />
										</div>
										<div className="details">
											<h3 className="name">{item.name}</h3>
										</div>
									</div>
								</div>
							))}
						</SimpleGrid>
					</Case>
				</Switch>
			</div>

			<When condition={pagination.total > 1}>
				<Pagination
					color="violet"
					active={pagination.active}
					total={pagination.total}
					onChange={(active) => setPagination((prev) => ({ ...prev, active }))}
				/>
			</When>
		</Container>
	);
};

export default ConnectorListStep;
