import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	min-height: 100vh;
	background: url(${require('../../static/images/auth-bg.png')});
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	top: 0;
	left: 0;

	.container {
		width: calc(100% - 48px);
		max-width: 800px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;

		> i {
			width: 400px;
			height: 400px;
			background: url(${require('../../static/images/broken-rocket.png')});
			z-index: 1;
		}

		> h1 {
			font-size: 104px;
			font-weight: 700;
			color: #ff8000;
		}

		> h3 {
			font-size: 32px;
			font-weight: 500;
			color: #fff;
		}

		> div {
			font-size: 16px;
			font-weight: 400;
			color: #edf3f7;
			margin-top: 8px;
		}

		> span {
			color: #ababab;
			font-weight: 400;
			text-align: center;
		}

		.download-tags {
			display: flex;
			width: 100%;
			background-color: #fff;
			align-items: center;
			justify-content: space-between;
			padding: 32px;
			border-radius: 10px;
			z-index: 1;
			margin-top: 72px;

			.brand {
				display: flex;
				column-gap: 16px;
				align-items: center;

				.logo {
					width: 80px;
					height: 80px;
					background: url(${require('../../static/images/square-logo.png')});
				}

				.info {
					display: flex;
					flex-direction: column;

					> span {
						&:first-child {
							font-size: 36px;
							color: #1d0a31;
							font-weight: 700;
						}

						&:last-child {
							color: #ff8000;
							font-size: 18px;
							font-weight: 500;
						}
					}
				}
			}

			.links {
				display: flex;
				justify-content: center;
				column-gap: 24px;

				> a {
					width: 168px;
					/* height: 48px;
					max-width: calc(50% - 24px); */

					img {
						width: 100%;
					}
				}
			}

			@media (max-width: 700px) {
				flex-direction: column;
				row-gap: 16px;
				padding: 16px;

				.links {
					width: 100%;
				}
			}
		}
	}
`;
