import { useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { debounce, get } from 'lodash';
import { StepPickStore } from './styles';
import Radio from '@kiwicom/orbit-components/lib/Radio';
import { searchStore } from '../../api';
import { useLocalStore } from './store';

import Table from '../../components/Table';
import { ImSearch } from 'react-icons/im';

const defaultImage = require('../../static/images/store-placeholder.svg').default;

const perPage = 5;

const Step = () => {
	const [pageCount, setPageCount] = useState(0);
	const [data, setData] = useState([]);
	const fetchIdRef = useRef(0);
	const [loading, setLoading] = useState(false);
	const [searchTerm, setSearchTerm] = useState('');
	const [selected, setSelected] = useState({ name: '', id: '' });

	const localStore = useLocalStore();

	const fetchAPIData = async ({ perPage, page, search }) => {
		try {
			setLoading(true);

			const { items = [], meta = {} } = await searchStore(page, perPage, search);

			setData(items);

			setPageCount(meta.pages);
			setLoading(false);
		} catch (e) {
			// console.log('Error while fetching', e);
			// setLoading(false)
		}
	};

	const fetchData = useCallback(() => {
		const fetchId = ++fetchIdRef.current;
		setLoading(true);
		if (fetchId === fetchIdRef.current) {
			fetchApply();
		}
	}, []);

	const fetchApply = () => {
		fetchAPIData({
			perPage,
			page: 1,
			search: searchTerm,
		});
	};

	const onConfirmSelect = () => {
		if (!selected.id) return;

		localStore.onSelectStore(selected);
	};

	useEffect(() => {
		if (!searchTerm.trim().length) {
			fetchApply();
		}
	}, [searchTerm]);

	const columns = useMemo(() => [
		{
			Header: '',
			accessor: 'select',
			show: true,
			Cell(data) {
				return (
					<Radio
						value={data.row.original.id}
						label=""
						name="selected-store-id"
						checked={selected.id === data.row.original.id}
						readOnly
					/>
				);
			},
		},
		{
			Header: '',
			accessor: 'store',
			show: true,
			Cell(data) {
				return (
					<div className="store-info">
						<div className="image">
							<img
								src={data.row.original.logoUrl || defaultImage}
								onError={({ currentTarget }) => {
									currentTarget.onerror = null;
									currentTarget.src = defaultImage;
								}}
							/>
						</div>
						<div className="info">
							<span className="name">{data.row.original.name}</span>
							<span className="address">{get(data.row.original, 'address.formattedAddress')}</span>
						</div>
					</div>
				);
			},
		},
	]);

	return (
		<StepPickStore>
			<div className="header">
				<div className="title">Find your store</div>
				<div className="subtitle">Fill in the fields below to complete the registration.</div>
			</div>

			<div className="request-info">
				<div className="email">
					<span>Your email: </span>
					<strong>{localStore.state.get().user.email}</strong>
				</div>
			</div>

			<div className="selector-content">
				<div className="search-content">
					<input
						id="searchTerm"
						name="searchTerm"
						type="text"
						onChange={(e) => setSearchTerm(e.target.value)}
						placeholder="Search..."
						onKeyDown={(event) => {
							if (event.key === 'Enter') {
								fetchApply();
							}
						}}
					/>

					<div className="action">
						<button className={searchTerm.length > 0 ? 'active' : ''} onClick={fetchApply}>
							<ImSearch />
						</button>
					</div>
				</div>

				<div className="table-content">
					<Table
						pageCount={pageCount}
						fetchData={fetchData}
						initialPageSize={perPage}
						columns={columns}
						loading={loading}
						data={data}
						isPaginated={false}
						onRowClick={(data) => {
							setSelected(data);
						}}
					/>

					{data.length < 1 && (
						<div className="empty-results">No stores found for these search terms.</div>
					)}
				</div>

				{selected.id && (
					<div className="selected-content">
						<div className="selected">Selected Store: {selected.name}</div>
					</div>
				)}

				<button onClick={onConfirmSelect} className="confirm-btn" disabled={!selected.id}>
					Confirm
				</button>
			</div>
		</StepPickStore>
	);
};

export default Step;
