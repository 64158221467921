import { useIntegration } from '../../hook';
import { Button, Modal, createStyles, Input, Checkbox } from '@mantine/core';
import { CustomModal } from './styles';
import endvrLogo from '../../assets/images/logo.png';
import { BsChevronCompactRight } from 'react-icons/bs';
import { VscChevronRight } from 'react-icons/vsc';
import { PiFlowArrowLight } from 'react-icons/pi';
import { When } from 'react-if';
import { useEffect, useMemo, useState } from 'react';
import { clone, cloneDeep, kebabCase, set } from 'lodash';

const modalStyles = createStyles((theme) => ({
	content: {
		padding: 0,
	},
}));

const ConnectorModal = ({ brandId }) => {
	const integration = useIntegration();

	const [domainPrefix, setDomainPrefix] = useState(integration.state.form.data.domainPrefix);
	const [warrantyAccepted, setWarrantyAccepted] = useState(false);

	const isValid = useMemo(() => {
		const domainValid = integration.state.form.data.hasDomainPrefix ? !!domainPrefix : true;
		return domainValid && warrantyAccepted;
	}, [integration.state.form.data.hasDomainPrefix, domainPrefix, warrantyAccepted]);

	useEffect(() => {
		if (!integration.state.form.modalOpen) {
			setDomainPrefix('');
			setWarrantyAccepted(false);
		}
	}, [integration.state.form.modalOpen]);

	useEffect(() => {
		if (integration.state.form.modalOpen) {
			setDomainPrefix(integration.state.form.data.domainPrefix);
		}
	}, [integration.state.form.modalOpen, integration.state.form.data.domainPrefix]);

	const getAuthUrl = () => {
		const formData = cloneDeep(integration.state.form.data);

		let authorizeHost = formData.authorizeHost;
		let authorizePath = formData.authorizePath;

		const query = new URLSearchParams({
			client_id: integration.state.form.data.clientId,
			response_type: 'code',
			redirect_uri: formData.redirectUrl,
			state: btoa(JSON.stringify({ brandId, domainPrefix })),
		});

		if (authorizePath.startsWith('/')) {
			authorizePath = authorizePath.slice(1);
		}

		if (formData.hasDomainPrefix) {
			authorizeHost = authorizeHost.replace('{{domainPrefix}}', domainPrefix);
		}

		let url = `${authorizeHost}/${authorizePath}?${query}`;

		if (formData.scope && formData.scope.length) {
			url += `&scope=${formData.scope.join('+')}`;
		}

		return url;
	};

	return (
		<CustomModal
			opened={integration.state.form.modalOpen}
			onClose={() => {
				integration.closeModal(false);
			}}
			closeOnClickOutside={false}
			centered
			size={590}
			radius="lg"
			withCloseButton={false}
			styles={modalStyles}
		>
			<div className="modal-body">
				<div className="header">
					<div className="logos-header">
						<svg
							className="union"
							width="254"
							height="144"
							viewBox="0 0 254 144"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<mask id="path-1-inside-1_16883_178809" fill="white">
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M71.8165 0.664062C32.3065 0.664062 0.277344 32.6932 0.277344 72.2032C0.277344 111.713 32.3065 143.742 71.8165 143.742C94.0844 143.742 113.976 133.568 127.097 117.615C140.217 133.568 160.109 143.742 182.377 143.742C221.887 143.742 253.916 111.713 253.916 72.2032C253.916 32.6932 221.887 0.664062 182.377 0.664062C160.109 0.664062 140.217 10.838 127.097 26.7912C113.976 10.838 94.0844 0.664062 71.8165 0.664062Z"
								/>
							</mask>
							<path
								d="M127.097 117.615L128.415 116.531L127.097 114.928L125.778 116.531L127.097 117.615ZM127.097 26.7912L125.778 27.8756L127.097 29.4788L128.415 27.8756L127.097 26.7912ZM1.98453 72.2032C1.98453 33.6361 33.2494 2.37125 71.8165 2.37125V-1.04312C31.3637 -1.04312 -1.42984 31.7504 -1.42984 72.2032H1.98453ZM71.8165 142.035C33.2494 142.035 1.98453 110.77 1.98453 72.2032H-1.42984C-1.42984 112.656 31.3637 145.45 71.8165 145.45V142.035ZM125.778 116.531C112.968 132.107 93.5524 142.035 71.8165 142.035V145.45C94.6164 145.45 114.984 135.03 128.415 118.7L125.778 116.531ZM182.377 142.035C160.641 142.035 141.225 132.107 128.415 116.531L125.778 118.7C139.209 135.03 159.577 145.45 182.377 145.45V142.035ZM252.209 72.2032C252.209 110.77 220.944 142.035 182.377 142.035V145.45C222.83 145.45 255.623 112.656 255.623 72.2032H252.209ZM182.377 2.37125C220.944 2.37125 252.209 33.6361 252.209 72.2032H255.623C255.623 31.7504 222.83 -1.04312 182.377 -1.04312V2.37125ZM128.415 27.8756C141.225 12.2998 160.641 2.37125 182.377 2.37125V-1.04312C159.577 -1.04312 139.209 9.37623 125.778 25.7067L128.415 27.8756ZM71.8165 2.37125C93.5524 2.37125 112.968 12.2998 125.778 27.8756L128.415 25.7067C114.984 9.37623 94.6164 -1.04312 71.8165 -1.04312V2.37125Z"
								fill="#EDF3F7"
								mask="url(#path-1-inside-1_16883_178809)"
							/>
						</svg>

						<div className="logo-container connector">
							<img src={integration.state.form.data.logoUrl} alt="" />
						</div>
						<div className="direction">
							{/*<VscChevronRight />*/}
							<PiFlowArrowLight />
						</div>
						<div className="logo-container endvr">
							<img src={endvrLogo} alt="" />
						</div>
					</div>

					<div className="description-header">
						<h3>Link your {integration.state.form.data.name} account to ENDVR</h3>

						<div>
							At ENDVR, we value the security and privacy of your data. We guarantee that your
							information is protected according to the highest security standards.
						</div>
					</div>
				</div>

				<When condition={integration.state.form.data.hasDomainPrefix}>
					<div className="domain-prefix-container">
						<Input.Wrapper
							id="domain-prefix"
							withAsterisk
							required={true}
							label="Shop ID"
							description={`Address of your ${integration.state.form.data.name} account`}
							// error="Your credit card expired"
							// inputWrapperOrder={['label', 'input', 'description', 'error']}
						>
							<Input
								id="input-demo"
								radius="lg"
								size="lg"
								value={domainPrefix}
								onChange={(event) => setDomainPrefix(event.currentTarget.value)}
								placeholder={`my-${kebabCase(integration.state.form.data.name)}-id`}
								rightSection={`.${integration.state.form.data.baseDomain}`}
								icon={
									<span style={{ color: '#4A4A4A', display: 'block', paddingLeft: '1rem' }}>
										https://
									</span>
								}
								iconWidth={70}
								iconSectionProps={{
									style: {
										pointerEvents: 'none',
										color: '#4A4A4A',
										justifyContent: 'flex-start',
										paddingLeft: '1rem',
									},
								}}
								rightSectionWidth={'50%'}
								rightSectionProps={{
									style: {
										pointerEvents: 'none',
										color: '#4A4A4A',
										justifyContent: 'flex-end',
										paddingRight: '1rem',
									},
								}}
							/>
						</Input.Wrapper>
					</div>
				</When>

				<div className="data-security-container">
					{/*<div className="intro">*/}
					{/*	By checking the box below, you agree to our Privacy Policy and Warranty Terms. These*/}
					{/*	terms outline our commitment to safeguarding your personal data.*/}
					{/*</div>*/}

					<Checkbox
						checked={warrantyAccepted}
						onChange={(event) => setWarrantyAccepted(event.currentTarget.checked)}
						label={
							<>
								I have read and agree to the{' '}
								<a
									href="https://endvr.nyc3.cdn.digitaloceanspaces.com/docs/endvr-pos-data-privacy-agreement.html"
									target="_blank"
									rel="noreferrer"
								>
									Data Privacy Agreement
								</a>
								, including how my data will be used.
							</>
						}
					/>
				</div>
			</div>
			<div className="modal-footer">
				<Button
					color="purple"
					variant="outline"
					radius="lg"
					size="md"
					onClick={() => integration.closeModal(false)}
				>
					Cancel
				</Button>
				<Button
					color="purple"
					radius="lg"
					size="md"
					disabled={!isValid}
					onClick={() => {
						const url = getAuthUrl();
						window.location.assign(url);

						setTimeout(() => {
							integration.closeModal(true);
							integration.setStep('brand-integrations-list');
						}, 100);
					}}
				>
					Continue
				</Button>
			</div>
		</CustomModal>
	);
};

export default ConnectorModal;
