import { Container } from './styles';
import { Scrollbars } from 'react-custom-scrollbars-2';

const Layout = ({ children }) => {
	return (
		<Container>
			<div className="banner-container">
				<i className="endvr" />
			</div>
			<div className="form-container">
				<div className="header">
					<i className="endvr" />
				</div>
				<div autoHide className="content">
					{children}
				</div>
			</div>
		</Container>
	);
};

export default Layout;
