import { StepStart } from './styles';
import { CgChevronRight } from 'react-icons/cg';

import { useLocalStore } from './store';

const Step = () => {
	const localStore = useLocalStore();

	return (
		<StepStart>
			<div className="header">
				<div className="title">
					Hi, <strong>welcome aboard!</strong>
				</div>
				<div className="subtitle">Fill in the fields below to complete the registration.</div>
			</div>

			<div className="request-info">
				<div className="email">
					<span>Your email: </span>
					<strong>{localStore.state.get().user.email}</strong>
				</div>
				<div className="store">
					<span>Registering at the store: </span>
					<strong>{localStore.state.get().store.name}</strong>
				</div>
			</div>

			<div className="options">
				<button onClick={() => localStore.setStep('form')} className="confirm">
					<i />
					<span>I work in this store and I want to continue</span>
					<CgChevronRight />
				</button>

				<button onClick={() => localStore.setStep('pick-store')} className="pick-another">
					<i />
					<span>I don't work at that store. See other stores</span>
					<CgChevronRight />
				</button>
			</div>
		</StepStart>
	);
};

export default Step;
