import { hookstate, useHookstate } from '@hookstate/core';
import { get } from 'lodash';
import { getStoreInfo } from '../../api';

const localState = hookstate({
	step: 'start',
	store: {
		id: '',
		name: '',
		logoUrl: '',
	},
	user: {
		name: '',
		email: '',
	},
});

export const useLocalStore = () => {
	const state = useHookstate(localState);

	const setStep = (step) => {
		state.set((prev) => ({
			...prev,
			step,
		}));
	};

	const setUserEmail = (email) => {
		state.set((prev) => ({
			...prev,
			user: {
				...prev.user,
				email,
			},
		}));
	};

	const setUserName = (name) => {
		state.set((prev) => ({
			...prev,
			user: {
				...prev.user,
				name,
			},
		}));
	};

	const loadData = async (id) => {
		const { status = false, store } = await getStoreInfo(id);
		if (!status) {
			return false;
		}

		state.set((row) => ({
			...row,
			store: {
				id: get(store, 'id', ''),
				name: get(store, 'name', ''),
				logoUrl: get(store, 'logoUrl', ''),
			},
		}));

		return true;
	};

	const onSelectStore = (store) => {
		state.set((row) => ({
			...row,
			store,
			step: 'form',
		}));
	};

	return {
		state,
		setStep,
		loadData,
		onSelectStore,
		setUserEmail,
		setUserName,
	};
};
