import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
});

instance.interceptors.response.use(
	(response) => {
		const { data = {} } = response;

		return data;
	},
	(error) => {
		console.log(error);
	}
);

export default instance;
