import request from './request';

export const confirmEmail = (token) => {
	return request.put('auth/mobile/confirm-email', {
		token,
	});
};

export const confirmResetPasswordEmail = (token) => {
	return request.put('user/write/mobile/confirm-reset-password-email', {
		token,
	});
};
