import { useState } from 'react';
import { StepForm } from './styles';
import { useLocalStore } from './store';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import { acceptLinkInvitation, verifyEmail } from '../../api';
import { If, Else, Then } from 'react-if';

const validationSchema = yup
.object({
  fullName: yup
  .string()
  .required('Name is required')
  .min(3, 'Name must be at least 3 characters'),
  password: yup.string().required().min(6),
})
.required();

const validationEmailSchema = yup
.object({
	email: yup
	.string()
	.required('Email is required')
	.email('Email format is invalid'),
})
.required();

const Step = () => {
  const localStore = useLocalStore();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

	const {
		register: registerEmail,
		handleSubmit: handleSubmitEmail,
		formState: { errors: errorsEmail },
	} = useForm({
		resolver: yupResolver(validationEmailSchema),
	});

  const onSubmit = async ({ fullName, password }) => {
    setLoading(true);
    const { status, message } = await acceptLinkInvitation({
      email: localStore.state.get().user.email,
      storeId: localStore.state.get().store.id,
      fullName,
      password,
    });

    setLoading(false);
    if (!status) {
      toast.error(message);
      return;
    }
    localStore.setUserName(fullName);
    localStore.setStep('confirmation');
  };

  const onAddEmail = async ({ email }) => {
    setLoading(true);
    const { status, message } = await verifyEmail({
      email,
    });

    setLoading(false);

    if (!status) {
      toast.error(message);
      return;
    }

    localStore.setUserEmail(email);
  };

  return (
    <StepForm>
      <div className="header">
        <div className="title">Create your account</div>
        <div className="subtitle">Fill in the fields below to complete the registration.</div>
      </div>

      <div className="request-info">
        <div className="email">
          <span>Your email: </span>
          <strong>{localStore.state.get().user.email}</strong>
        </div>
        <div className="store">
          <span>Registering at the store: </span>
          <strong>{localStore.state.get().store.name}</strong>
        </div>
      </div>

      <If condition={localStore.state.get().user.email === ''}>
        <Then>
          <div className="reg-form-container">
            <form onSubmit={handleSubmitEmail(onAddEmail)}>
							<div className="input-group">
								<label htmlFor="#form-email">Email</label>
								<input readOnly={loading} type="text" id="form-email" {...registerEmail('email')} />
								<span className="validation">{errorsEmail.email?.message}</span>
							</div>
							<button disabled={loading} type="submit" className="confirm-btn">
								Confirm
							</button>
            </form>
          </div>
        </Then>
        <Else>
          <div className="reg-form-container">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="input-group">
                <label htmlFor="#form-name">Name</label>
                <input readOnly={loading} type="text" id="form-name" {...register('fullName')} />
                <span className="validation">{errors.fullName?.message}</span>
              </div>

              <div className="input-group">
                <label htmlFor="#form-password">Password</label>
                <input
                  readOnly={loading}
                  type="password"
                  id="form-password"
                  {...register('password')}
                />
                <span className="validation">{errors.password?.message}</span>
              </div>

              <button disabled={loading} type="submit" className="confirm-btn">
                Confirm
              </button>
            </form>
          </div>
        </Else>
      </If>
    </StepForm>
  );
};

export default Step;
