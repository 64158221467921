export const removeQueryParameterUsingURL = (url, parameter) => {
	const urlObj = new URL(url);
	urlObj.searchParams.delete(parameter);
	return urlObj.toString();
};

export const removeQueryParameterAndReplaceInBrowser = (parameter) => {
	const url = new URL(window.location.href);
	url.searchParams.delete(parameter);
	window.history.replaceState({}, '', url);
};
