import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100vw;
	min-height: 100vh;
	background: url(${require('../../static/images/mobile-bg.png')});
	background-repeat: no-repeat;
	background-size: cover;
	position: fixed;
	top: 0;
	left: 0;

  > .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

	.container {
		width: calc(100% - 48px);
		max-width: 800px;
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
    gap: 16px;
    text-align: center;

		> i {
			width: 168px;
			height: 203px;
			background: url(${require('../../static/images/et-spaceship.png')});
      background-repeat: no-repeat;
			z-index: 1;
		}

		> h1 {
			font-size: clamp(1.5rem, 0.7399rem + 3.2432vw, 3.375rem);
			font-weight: 700;
			color: #fff;
		}

		> span {
			color: #fff;
			font-weight: 400;
      font-size: clamp(0.75rem, 0.598rem + 0.6486vw, 1.125rem);
			text-align: center;
		}

	}
`;
