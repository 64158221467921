import styled from 'styled-components';

import emptyImage from '../../assets/images/empty-page.png';

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 24px;
	margin-top: 48px;

	.result-container {
		position: relative;
		flex: 1;
		min-height: 200px;
		.empty {
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;

			.icon {
				width: 360px;
				height: 360px;
				max-width: 100%;
				max-height: 100%;
				object-fit: cover;
				background-image: url(${emptyImage});
			}

			h3 {
				color: #ababab;
				font-size: 16px;
				font-style: normal;
				font-weight: 700;
				line-height: 24px;
			}
		}

		.result-grid {
			> div {
				padding: 16px;
				border-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: space-between;
				background-color: #edf3f7;
				aspect-ratio: 1 / 1;
				cursor: pointer;

				&:hover {
					background-color: #e2e8ed;
				}

				.connector-info {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					row-gap: 16px;
					width: 100%;

					.logo {
						width: calc(100% - 60px);
						aspect-ratio: 1 / 1;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.details {
						display: flex;
						flex-direction: column;

						row-gap: 4px;

						.name {
							font-size: 14px;
							font-style: normal;
						}

						.status {
							font-size: 12px;
						}
					}
				}
			}
		}
	}
`;
