import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';

const app = ReactDOM.createRoot(document.getElementById('app'));

app.render(<App />);

reportWebVitals();
