import request from './request';

export const getInvitationInfo = (uuid) => {
	return request.get(`user/invitation/${uuid}`);
};

export const acceptInvitation = ({ uuid, storeId, fullName, password }) => {
	return request.post('user/invitation/accept', {
		uuid,
		storeId,
		fullName,
		password,
	});
};
export const acceptLinkInvitation = ({ email, storeId, fullName, password }) => {
	return request.post('user/link-invitation/accept', {
		email,
		storeId,
		fullName,
		password,
	});
};

export const verifyEmail = ({ email }) => {
	return request.post('user/verify-email', {
		email,
	});
};
