import styled from 'styled-components';
import media from 'styled-media-query';

const BaseStep = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;

  .confirm-btn {
    width: 100%;
    height: 48px;
    background-color: #6f56bc;
    color: #fff;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    margin-top: 20px;

    &:hover {
      opacity: 0.85;
    }

    &:disabled {
      background-color: #aaa9ae;
      cursor: not-allowed;

      &:hover {
        opacity: 1;
      }
    }
  }

  > .header {
    .title {
      font-size: 36px;
      font-weight: 700;
      color: var(--color-black);
      margin-bottom: 8px;
    }

    .subtitle {
      color: var(--color-gray);
      font-size: 16px;
      font-weight: 400;
    }
  }

  > .request-info {
    color: var(--color-gray);

    .email {
      font-size: 12px;
    }

    .store {
      font-size: 18px;
      padding-top: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      strong {
        font-size: 24px;
        font-weight: 700;
        color: var(--color-primary);
      }
    }
  }

  ${media.lessThan('medium')`
    > .header {
			.title {
				font-size: 32px;
				strong {

				}
			}
		}

		> .request-info {
			.store {
				span {
					font-size: 12px;
				}

				strong {
					font-size: 16px;
				}
			}
		}
  `}
`;

export const Container = styled.div`
  > .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StepStart = styled(BaseStep)`
  > .header {
    .title {
      font-size: 54px;

      strong {
        color: var(--color-primary);
      }
    }
  }

  > .options {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    > button {
      height: 72px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 16px;
      border-radius: 10px;
      background-color: #edf3f7;
      border: 1px solid #edf3f7;
      cursor: pointer;
      column-gap: 16px;

      > i {
        width: 40px;
        height: 40px;
      }

      > span {
        font-size: 18px;
        font-weight: 700;
      }

      > svg {
        width: 30px;
        height: 30px;
        margin-left: auto;
      }

      &.confirm {
        > i {
          background-image: url(${require('../../static/images/icon-store-ok.png')});
        }

        > svg,
        > span {
          color: var(--color-primary);
        }
      }

      &.pick-another {
        > i {
          background-image: url(${require('../../static/images/icon-store-error.png')});
        }

        > svg,
        > span {
          color: var(--color-red);
        }
      }
    }
  }

  ${media.lessThan('medium')`
    > .header {
			.title {
				font-size: 32px;
				strong {

				}
			}
		}

		> .options {
			> button {
				> span {
					font-size: 12px;
					max-width: calc(100% - 102px);
					text-align: left;
				}
			}
		}
  `}
`;

export const StepPickStore = styled(BaseStep)`
  .selector-content {
    .search-content {
      width: 100%;
      height: 40px;
      position: relative;

      input {
        width: 100%;
        height: 40px;
        border-radius: 10px;
        border: 1px solid #e1e1e1;
        padding: 0 16px;
      }

      .action {
        position: absolute;
        top: 5px;
        right: 16px;
        width: 30px;
        height: 30px;

        > button {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          border-radius: 50%;
          border: none;
          color: #ababab;
          background-color: transparent;

          svg {
            width: 18px;
            height: 18px;
          }

          &.active {
            background-color: #6f56bc;
            color: #fff;
          }
        }
      }
    }

    .table-content {
      table {
        border-collapse: separate;
        border-spacing: 0 12px;

        thead {
        }

        tbody {
          tr {
            cursor: pointer;

            td {
              vertical-align: middle;

              &:first-child {
                padding-right: 16px;
              }

              .store-info {
                display: flex;
                column-gap: 8px;

                .image {
                  width: 40px;
                  height: 40px;
                  border-radius: 5px;
                  overflow: hidden;

                  img {
                    width: 100%;
                  }
                }

                .info {
                  width: calc(100% - 48px);
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  row-gap: 4px;

                  > * {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }

                  .name {
                    font-size: 16px;
                    font-weight: 700;
                  }

                  .address {
                    font-size: 12px;
                    color: #777777;
                  }
                }
              }
            }
          }
        }
      }

      .empty-results {
        background-color: #edf3f7;
        height: 80px;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        color: #b4b4b4;
        font-weight: 500;
      }
    }

    .selected-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;

      .selected {
        font-size: 12px;
        padding: 4px 6px;
        background-color: #f4f4f4;
        border-radius: 4px;
        font-weight: 500;
      }
    }
  }
`;

export const StepForm = styled(BaseStep)`
  .reg-form-container {
    > form {
      display: flex;
      flex-direction: column;
      row-gap: 24px;

      .input-group {
        display: flex;
        flex-direction: column;

        > label {
          font-size: 16px;
          font-weight: 700;
          color: var(--color-black);
          margin-bottom: 4px;
        }

        > input {
          width: 100%;
          height: 48px;
          border-radius: 10px;
          border: 1px solid #777777;
          padding: 0 16px;
        }

        > .validation {
          font-size: 12px;
          color: #e64444;
        }
      }
    }
  }
`;

export const ConfirmContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  min-height: 100vh;
  background: url(${require('../../static/images/auth-bg.png')});
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;

  &::before {
    content: '';
    width: 734px;
    height: 1024px;
    max-height: 90%;
    max-width: 100%;
    background: url(${require('../../static/images/auth-banner-bg.png')});
    position: absolute;
    bottom: 0;
    left: 0;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    
    @media (max-width: 480px) {
      background: url(${require('../../static/images/auth-banner-sm-bg.png')});
      background-repeat: no-repeat;
      max-height: 100%;
    }
  }

  .container {
    width: calc(100% - 48px);
    max-width: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    > i {
      width: 160px;
      height: 160px;
      background: url(${require('../../static/images/icon-check.png')});
      background-size: contain;
      margin-bottom: 32px;
      z-index: 1;

      @media (max-width: 480px) {
        width: 112px;
        height: 112px;
      }
    }

    > h1 {
      font-size: clamp(1.5rem, 0.8919rem + 2.5946vw, 3rem);
      font-weight: 700;
      color: #fff;
      margin-bottom: 16px;
    }

    > h3 {
      font-size: clamp(0.75rem, 0.598rem + 0.6486vw, 1.125rem);
      font-weight: 500;
      color: #fff;
    }

    > span {
      color: #ababab;
      font-weight: 400;
      font-size: clamp(0.75rem, 0.6486rem + 0.4324vw, 1rem);
      margin-top: 2px;
      text-align: center;
    }

    .download-tags {
      display: flex;
      width: 100%;
      background-color: #fff;
      align-items: center;
      justify-content: space-between;
      padding: 32px;
      border-radius: 10px;
      z-index: 1;
      margin-top: 72px;
      
      @media (max-width: 480px) {
        margin-top: 32px;
      }

      .brand {
        display: flex;
        column-gap: 16px;
        align-items: center;

        .logo {
          width: 80px;
          height: 80px;
          background: url(${require('../../static/images/square-logo.png')});
        }

        .info {
          display: flex;
          flex-direction: column;

          > span {
            &:first-child {
              font-size: 36px;
              color: #1d0a31;
              font-weight: 700;
            }
          }
        }
      }

      .links {
        display: flex;
        justify-content: center;
        column-gap: 24px;

        > a {
          width: 168px;
          /* height: 48px;
          max-width: calc(50% - 24px); */

          img {
            width: 100%;
          }
        }
      }

      @media (max-width: 700px) {
        flex-direction: column;
        row-gap: 16px;
        padding: 16px;

        .links {
          width: 100%;
        }
      }
    }
  }
`;
