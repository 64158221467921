import request from './request';
import queryString from 'query-string';

export const getStoreInfo = (storeId) => {
	return request.get(`store/${storeId}`);
};

export const searchStore = (page = 1, perPage = 5, search = '') => {
	const query = queryString.stringify({
		page,
		perPage,
		search,
	});

	return request.get(`/store/search?${query}`);
};
