import { MantineProvider, Container } from '@mantine/core';
import { Notifications, notifications } from '@mantine/notifications';
import { useIntegration } from './hook';
import { Case, Switch } from 'react-if';
import { useParams } from 'react-router-dom';

/** Steps  **/
import BrandIntegrationListStep from './steps/BrandIntegrationListStep';
import ConnectorListStep from './steps/ConnectorListStep';
import ConnectorModal from './components/ConnectorModal';
import { useEffect } from 'react';
import { removeQueryParameterAndReplaceInBrowser } from './utils';

const Integration = () => {
	const integration = useIntegration();
	const { brandId } = useParams();

	const query = new URLSearchParams(window.location.search);
	const oauthResult = query.get('oauth-result');

	useEffect(() => {
		if (oauthResult) {
			if (oauthResult === 'success') {
				notifications.show({
					title: 'Success',
					message:
						"Integration successfully created, if it's not showing up, please refresh the page",
					color: 'green',
					autoClose: 10000,
				});
			} else if (oauthResult === 'failed') {
				notifications.show({
					title: 'Error',
					message: 'Integration creation failed',
					color: 'red',
				});
			}

			removeQueryParameterAndReplaceInBrowser('oauth-result');
		}
	}, []);

	return (
		<MantineProvider
			withGlobalStyles
			withNormalizeCSS
			theme={{
				colors: {
					purple: [
						'#f5eeff',
						'#e2dcf7',
						'#c2b8e5',
						'#a190d4',
						'#846fc6',
						'#725abd',
						'#694fba',
						'#583fa5',
						'#4f3894',
						'#432f83',
					],
				},
				primaryColor: 'purple',
				fontFamily: 'Roboto, sans-serif',
				components: {
					Container: {
						defaultProps: {
							sizes: {
								xs: 540,
								sm: 720,
								md: 960,
								lg: 1140,
								xl: 1320,
							},
						},
					},
				},
			}}
		>
			<Container>
				<Notifications position="top-center" />
				<ConnectorModal brandId={brandId} />
				<Switch>
					<Case condition={integration.state.currentStep === 'brand-integrations-list'}>
						<BrandIntegrationListStep brandId={brandId} />
					</Case>
					<Case condition={integration.state.currentStep === 'connector-list'}>
						<ConnectorListStep brandId={brandId} />
					</Case>
				</Switch>
			</Container>
		</MantineProvider>
	);
};

export default Integration;
