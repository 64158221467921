import axios from 'axios';
import { get } from 'lodash';

const baseURL = get(process.env, 'REACT_APP_INTEGRATION_API_URL', '');

const instance = axios.create({
	baseURL,
});

instance.interceptors.response.use((response) => {
	const { data = {} } = response;

	return data;
});

export default instance;
