import { Container } from './styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { confirmResetPasswordEmail } from '../../api-v2';
import GridLoader from 'react-spinners/GridLoader';
import { When } from 'react-if';

const Page = () => {
	const { search } = useLocation();
	const token = new URLSearchParams(search).get('token');
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const sendRequest = async () => {
		setLoading(true);
		try {
			const { result } = await confirmResetPasswordEmail(token);
			if (!result.status) {
				console.log('error');
				navigate('/');
				return;
			}
		} catch (error) {
			navigate('/');
			return;
		}
		setLoading(false);

		window.location.replace("https://endvr.page.link/1bwQ");
	}

	useEffect(() => { sendRequest() }, []);

	return (
		<Container>
			<When condition={loading}>
				<h1>We are validating your email...</h1>
				<div className="loading">
					<GridLoader size={80} color="#fff" />
				</div>
			</When>
			<div className="container">
				<i />
				<h1>Please open it on your mobile app</h1>
				<span>The device used is not compatible with the action you wish to perform, please repeat this action using a mobile device.</span>
			</div>
		</Container>
	);
};

export default Page;
