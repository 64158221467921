import { Container } from './styles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { When } from 'react-if';
import GridLoader from 'react-spinners/GridLoader';
import { confirmEmail } from '../../api-v2';

const Page = () => {
  const { search } = useLocation();
  const token = new URLSearchParams(search).get('token');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const sendRequest = async () => {
    setLoading(true);
    try {
      const { result } = await confirmEmail(token);
      if (!result.status) {
        console.log('error');
        navigate('/');
        return;
      }
    } catch (error) {
      navigate('/');
      return;
    }
    setLoading(false);

    window.location.replace("https://endvr.page.link/1bwQ");
  }

  useEffect(() => { sendRequest() }, []);

  return (
    <Container>
     <When condition={loading}>
       <h1>We are validating your email...</h1>
       <div className="loading">
         <GridLoader size={80} color="#fff" />
       </div>
     </When>
    </Container>
  );
};

export default Page;
