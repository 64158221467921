import Header from '../../components/Header';
import { Button, Pagination, Badge, Switch as SwitchInput, LoadingOverlay } from '@mantine/core';
import { LiaPlusSolid } from 'react-icons/lia';
import { Container } from './styles';
import { useEffect, useState } from 'react';
import { Switch, Case, When, Default } from 'react-if';
import request from '../../service';
import { useIntegration } from '../../hook';
import { notifications } from '@mantine/notifications';

const BrandIntegrationListStep = ({ brandId }) => {
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	const integration = useIntegration();

	const [pagination, setPagination] = useState({
		active: 1,
		perPage: 10,
		total: 1,
	});

	useEffect(() => {
		loadData();
	}, [brandId, pagination.active]);

	const loadData = async () => {
		setLoading(true);

		const query = new URLSearchParams({
			currentPage: pagination.active,
			perPage: pagination.perPage,
		});

		const { items = [], _meta } = await request.get(`/web/brand/${brandId}/connectors?${query}`);

		setData(items);

		setPagination((prev) => ({
			...prev,
			active: _meta.currentPage,
			perPage: _meta.perPage,
			total: _meta.pageCount,
		}));

		setLoading(false);
	};

	const changeConnectorStatus = async (connectorId, isActive) => {
		const { status = false } = await request.put(
			`/web/brand/${brandId}/connector/${connectorId}/active`,
			{ isActive }
		);

		if (status) {
			notifications.show({
				title: 'Success',
				message: 'Connector status updated successfully',
				color: 'green',
			});
		} else {
			notifications.show({
				title: 'Error',
				message: 'Something went wrong',
				color: 'red',
			});
		}
	};

	return (
		<Container>
			<Header pageTitle="ENDVR Integration">
				<Button
					onClick={() => integration.setStep('connector-list')}
					radius="xl"
					color="purple"
					leftIcon={<LiaPlusSolid />}
				>
					Add Integration
				</Button>
			</Header>

			<div className="result-container">
				<LoadingOverlay visible={loading} zIndex={1000} overlayProps={{ radius: 'sm', blur: 2 }} />
				<Switch>
					<Case condition={loading}>Loading...</Case>
					<Case condition={data.length === 0}>
						<div className="empty">
							<div className="icon" />
							<h3>You currently have no integrations.</h3>
						</div>
					</Case>
					<Case condition={data.length > 0}>
						<ul className="list">
							{data.map((item) => (
								<li key={`result-item-${item.id}`}>
									<div className="connector-info">
										<div className="logo">
											<img src={item.connector.logoUrl} alt={item.connector.name} />
										</div>
										<div className="details">
											<h3 className="name">{item.connector.name}</h3>
											<div className="status">
												<Switch>
													<Case condition={item.status === 'connected'}>
														<Badge color="green">Connected</Badge>
													</Case>
													<Case condition={item.status === 'pending'}>
														<Badge color="gray">Pending</Badge>
													</Case>
													<Case condition={item.status === 'expired'}>
														<Badge color="red">Authorization Expired</Badge>
													</Case>
													<Case condition={item.status === 'failed'}>
														<Badge color="red">Failed</Badge>
													</Case>
												</Switch>
											</div>
										</div>
									</div>

									<div className="actions">
										<Switch>
											<Case condition={item.status === 'connected'}>
												<SwitchInput
													size="lg"
													defaultChecked={item.isActive}
													color="purple"
													onChange={(event) =>
														changeConnectorStatus(item.connector.key, event.target.checked)
													}
												/>
											</Case>
											<Case condition={item.status === 'expired'}>
												<Button
													color="green"
													radius="xl"
													size="xs"
													onClick={() =>
														integration.editBrandConnector(brandId, item.connector.key)
													}
												>
													Reauthorize
												</Button>
											</Case>
											<Case condition={item.status === 'failed'}>
												<Button
													color="green"
													radius="xl"
													size="xs"
													onClick={() =>
														integration.editBrandConnector(brandId, item.connector.key)
													}
												>
													Try again
												</Button>
											</Case>
											<Default>
												<SwitchInput size="lg" defaultChecked={false} disabled color="purple" />
											</Default>
										</Switch>
									</div>
								</li>
							))}
						</ul>
					</Case>
				</Switch>
			</div>

			<When condition={pagination.total > 1}>
				<Pagination
					color="violet"
					active={pagination.active}
					total={pagination.total}
					onChange={(active) => setPagination((prev) => ({ ...prev, active }))}
				/>
			</When>
		</Container>
	);
};

export default BrandIntegrationListStep;
