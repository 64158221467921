import styled from 'styled-components';

export const Container = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	.main {
		.title {
			color: var(--neutral-text, var(--color-gray-800, #4a4a4a));
			font-size: 16px;
			font-style: normal;
			font-weight: 700;
			line-height: 24px;
		}
	}
`;
